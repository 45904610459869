import * as React from "react"
import { Seo } from "../../components/seo"
import Layout from '../../components/layout'
import Sidebar from '../../components/sidebar'
import { StaticImage } from "gatsby-plugin-image"

const ShopPage = () => {
  return (
	<Layout>
		<section id="hero">
			<StaticImage src="../../images/hero-images/hero-image-3.jpg" alt="Hero Background" class="heroImage" />
			<div className="heroOverlay"></div>
			<div className="container">
				<h1>Shop</h1>
			</div>
		</section>
		<section id="pageContent">
			<div className="container withSidebar">
				<main>
					<h2>Shopping at the ReStore supports safe, decent, affordable housing.</h2>
					<p>
						Our mission is to be a fundraiser for Habitat for Humanity in Oneida and Vilas counties.
						All proceeds support local Habitat for Humanity home construction projects.
					</p>
					<p>
						The ReStore helps the community by recycling gently used items, keeping them from the landfill, and providing a variety of affordable, home-improvement supplies.
					</p>
				</main>
				<Sidebar />
			</div>
		</section>
	</Layout>
  )
}
export default ShopPage

export const Head = () => (
	<Seo
        title="Shop | Habitat For Humanity Northwoods Wisconsin"
    />
)
